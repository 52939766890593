import { action, observable } from 'mobx';
import UserStore from './UserStore';
import * as api from '../../services/api';

class ScanningStore {
    @observable scannedItem = null;
    @observable scannedItemSKU = '';

    @action handleScan = (scannedBarcode, handledScannedItemCallback, handledScannedWarmerItemCallback) => {
        let ids = scannedBarcode.split('-');
        if (ids.length === 3) {
            handledScannedWarmerItemCallback(ids[0], ids[1], ids[2]);
            this.scannedItemSKU = ids[1];
        } else if (ids.length === 5 && (ids[0] === 'DI' || ids[0] === 'TA')) {
            //ids[0]=DI/TA,ids[1]=sku,ids[2]=today_menu_id,ids[3]=incremental_number,ids[4]=pick_up_number
            handledScannedItemCallback(
                ids[2],
                ids[3],
                ids[4]
            );
            this.scannedItemSKU = ids[1];
        } else if (ids.length === 5 && ids[0] === 'DE') {
            //ids[0]=DE,ids[1]=sku,ids[2]=today_menu_id,ids[3]=incremental_number,ids[4]=pick_up_number
            handledScannedItemCallback(
                ids[2],
                ids[3],
                ids[4]
            );
        } else {
            api.sendLogToBackend('Unknown scan format: ' + scannedBarcode);
        }
    };

    LOCAL = 'LOCAL';
    CLOUD = 'CLOUD';
    @action printToLocalOrCloudPrinters = (
        isAutomaticPrinting,
        isKitchenLabel,
        dataToWriteLocalAndCloudPrinters,
        successCallback,
        errorCallback
    ) => {
        const { selectedHub } = UserStore;
        if (isKitchenLabel) {
            if (isAutomaticPrinting) {
                // Automatic printing is handled by backend if cloud printers are enabled
                return;
            }
            // call backend with kitchenPrinterId
            console.log(dataToWriteLocalAndCloudPrinters.CLOUD);
            api.printLabel(
                dataToWriteLocalAndCloudPrinters.CLOUD,
                selectedHub.kitchenPrinterId
            )
            .then((res) => {
                if (successCallback) successCallback(res);
            })
            .catch((err) => {
                if (errorCallback) errorCallback(err);
            });

        } else {
            if (isAutomaticPrinting) {
                // Automatic printing is handled by backend if cloud printers are enabled
                return;
            }
            // call backend with dispatchPrinterId
            console.log(dataToWriteLocalAndCloudPrinters.CLOUD);
            api.printLabel(
                dataToWriteLocalAndCloudPrinters.CLOUD,
                selectedHub.dispatchPrinterId
            )
            .then((res) => {
                if (successCallback) successCallback(res);
            })
            .catch((err) => {
                if (errorCallback) errorCallback(err);
            });

        }
    };
}

const store = new ScanningStore();
export default store;
